<template>
  <BTableCard
    :searchActive="false"
    :newAddActive="false"
    :totalActive="false"
    :show="show"
    :icon="'fad fa-user'"
    :title="'Kullanıcı Profili'"
    :total="0"
  >
    <template v-slot:body>
      <KullaniciProfil @show="show = $event" />
    </template>
  </BTableCard>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import BTableCard from '@/components/cards/BTableCard.vue';
import KullaniciProfil from '../component/KullaniciProfil.vue';
export default defineComponent({
  components: { BTableCard, KullaniciProfil },
  setup() {
    const expo = {};
    expo.show = ref(false);

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
