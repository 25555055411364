<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" class="p-2" autocomplete="off">
      <b-row>
        <b-col cols="12">
          <validation-provider name="Ad Soyad" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Ad Soyad">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="ad_soyad"
                v-model="form.ad_soyad"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <validation-provider name="Kullanıcı Adı ( Email )" :rules="{ required: true, email: true }" v-slot="validationContext">
            <b-form-group label="Kullanıcı Adı ( Email )">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="email"
                v-model="form.email"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6">
          <validation-provider name="Şifre" :rules="userSifreControl" v-slot="validationContext">
            <b-form-group label="Şifre">
              <b-input-group>
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  ref="sifre"
                  v-model="form.sifre"
                  :type="passwordFieldType"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />

                <b-input-group-prepend is-text>
                  <i :class="`fad fa-${passwordToggleIcon}`" @click="togglePasswordVisibility" />
                </b-input-group-prepend>
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Telefon" label-for="telefon">
            <b-form-input size="lg" class="rounded-0" id="telefon" name="telefon" ref="telefon" v-model="form.telefon" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Profil Resmi" label-for="role_adi">
            <b-form-file
              size="lg"
              placeholder="Bir resim seçiniz"
              drop-placeholder="Drop file here..."
              no-drop
              v-model="profile"
              accept="image/jpeg, image/png, image/jpg"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button squared block type="submit" size="lg" variant="primary" class="mr-2">
              <i class="fad fa-save pr-2"></i> KAYDET
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import { defineComponent, ref, computed, toRefs, watch, onMounted } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import store from '@/store';
export default defineComponent({
  components: {
    vSelect,
  },
  props: {},
  setup(_, context) {
    const expo = {};
    const toast = useToast();

    expo.profile = ref(null);
    expo.roller = ref([]);
    expo.master = ref('user');
    expo.passwordFieldType = ref('password');
    expo.form = ref({
      k_no: null,
      ad_soyad: null,
      e_mail: null,
      sifre: null,
      telefon: null,
      role_k_no: null,
      statu: true,
    });

    expo.passwordToggleIcon = computed(() => {
      return expo.passwordFieldType.value === 'password' ? 'eye-slash' : 'eye';
    });

    expo.togglePasswordVisibility = () => {
      expo.passwordFieldType.value = expo.passwordFieldType.value === 'password' ? 'text' : 'password';
    };

    expo.userSifreControl = computed(() => {
      if (expo.form.value.k_no != null) {
        return { required: false };
      } else {
        return { required: true, min: 4 };
      }
    });

    expo.resetForm = () => {
      expo.form.value = {
        k_no: null,
        ad_soyad: null,
        email: null,
        sifre: null,
        telefon: null,
        role_k_no: null,
        statu: true,
      };
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('show', true);
      let formData = new FormData();
      let forms = expo.form.value;
      Object.keys(forms).map(function (key) {
        formData.append(key, forms[key]);
      });

      if (expo.profile.value !== null) {
        formData.append('profile', expo.profile.value, expo.profile.value.name);
      }

      // if (firma_k_no.value != null) {
      //   formData.append('firma_k_no', firma_k_no.value);
      // }
      store.dispatch('kullaniciGuncelle', formData).then((res) => {
        if (res.data.success === true) {
          toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
          context.emit('show', false);
        }
      });
    };

    onMounted(() => {
      context.emit('show', true);
      const user = store.getters.getUserData;

      store.dispatch('kullaniciGetir', user).then((res) => {
        if (res.data.success) {
          expo.form.value = res.data.data;
        }
        context.emit('show', false);
      });
    });

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
